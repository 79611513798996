<template>
  <div>

    <CRow>
      <CCol lg="12">
        <CCard>
        <CCardHeader>
          <slot name="header">
            <!-- <CIcon name="cil-grid"/> --><h4 class="float-left">Laporan per Wilayah</h4> 
            <select style="margin-left:15px;width:100px" @change="loadData" v-model="tahun" class="form-control-sm float-left form-control">
              <option v-for="year in years" :value="year">{{ year }}</option>
            </select> 
            <select style="margin-left:15px;width:120px" @change="loadData" v-model="wilayah" class="form-control-sm float-left form-control">
              <option v-for='opt in wilayahs' :value="opt">{{opt}}</option>
            </select>
              <a href="https://api.bkad-sigagah.com/laporan-per-wilayah-pdf" target="_blank" class="float-right btn btn-danger form-control-sm" >PDF </a>
              <a href="https://api.bkad-sigagah.com/laporan-per-wilayah-excel" target="_blank" class="float-right btn btn-warning form-control-sm" style='margin-right:10px'>Excel </a>
          </slot>
        </CCardHeader>
        <CCardBody>
      
        <table class="table table-striped table-responsive w-100" id="datatable">
          <thead class="bg-info">
            <tr>
              <th>#</th>
              <th>Kota/Kabupaten</th>
              <th>Kecamatan</th>
              <th>Kelurahan</th>
              <th>Kode SKPD</th>
              <th>Nama SKPD</th>
              <th>Kode Lokasi</th>
              <th>Nama OPD</th>
              <th>Kode Barang</th>
              <th>Nama Barang</th>
              <th>Tgl Perolehan</th>
              <th>Letak/Alamat</th>
              <th>Luas (m2)</th>
              <th>Tgl Sertifikat</th>
              <th>Harga Perolehan</th>
            </tr>
          </thead>
          <tbody>
            
          </tbody>
        </table>  

    </CCardBody>
  </CCard>  
  
  </CCol>
    </CRow>
  </div>
</template>
 
<script>
import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from 'axios';
import $ from 'jquery'; 

export default {
  // methods: {
  //   numberWithCommas(x) {
  //       return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  //   }
  // },
  filters: {
    rupiah: function(value) {
      // return value.toUpperCase();
      // Create our number formatter.
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(value); /* $2,500.00 */
    }
  },
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  mounted(){

    this.loadData();
  },
  data: function() {
        return {
            wilayah: "PILIH KOTA/KAB" ,
            wilayahs:['PILIH KOTA/KAB','PONTIANAK','SINGKAWANG','BENGKAYANG','MEMPAWAH','MELAWI','KAYONG UTARA','KAPUAS HULU','KETAPANG','KUBU RAYA','LANDAK','SAMBAS','SANGGAU','SEKADAU','SINTANG'],
            products:[],
            tahun: new Date().getFullYear(),
        }
  },
  methods: {
    loadData: function() {
      if (this.tahun == null){
      
          $('#datatable').DataTable({
            "processing": true,
              "serverSide": true,
               "pageLength": 50,
               responsive: true,
              "ajax":{
                     "url": process.env.VUE_APP_BASE_URL+"laporanwilayah",
                     "dataType": "json",
                     "type": "POST"
                     },
              "columns": [
                  { "data": "no",class:"text-center"},
                  { "data": "kabupaten_kota",class:"text-center"}, 
                  { "data": "kecamatan" ,class:"text-center"}, 
                  { "data": "kelurahan" ,class:"text-center"},
                  { "data": "kode_skpd" ,class:"text-center"},
                  { "data": "nama_skpd" ,class:"text-center"},
                  { "data": "kode_lokasi" ,class:"text-center"},
                  { "data": "nama_instansi" },
                  { "data": "kode_barang" },
                  { "data": "nama_barang" },
                  { "data": "tgl_perolehan",class:"text-center"},
                  { "data": "letak_lokasi" },
                  { "data": "luas_tertulis" ,class:"text-center"},
                  { "data": "tgl_sertifikat" ,class:"text-center"},
                  { "data": "nilai_perolehan" ,class:"text-center"},
                  // { "data": null,
                  //   render: function(data, type, row, meta)
                  //     {
                  //         return "<a href=''>Detail</a>";
                  //     },
                  // },
              ]  

          });

          $.fn.dataTableExt.sErrMode = 'throw';
          
        // })
      }
      else{
        
          $('#datatable').DataTable({
                      "processing": true,
              "serverSide": true,
               "pageLength": 50,
               responsive: true,
              "ajax":{
                     "url": process.env.VUE_APP_BASE_URL+"laporanwilayah",
                     "dataType": "json",
                     "type": "POST"
                     },
              "columns": [
                  { "data": "no",class:"text-center" },
                  { "data": "kabupaten_kota",class:"text-center" }, 
                  { "data": "kecamatan" ,class:"text-center"}, 
                  { "data": "kelurahan" ,class:"text-center"},
                  { "data": "kode_skpd" ,class:"text-center"},
                  { "data": "nama_skpd" ,class:"text-center"},
                  { "data": "kode_lokasi" ,class:"text-center"},
                  { "data": "nama_instansi" },
                  { "data": "kode_barang" },
                  { "data": "nama_barang" },
                  { "data": "tgl_perolehan",class:"text-center" },
                  { "data": "letak_lokasi" },
                  { "data": "luas_tertulis" ,class:"text-center"},
                  { "data": "tgl_sertifikat" ,class:"text-center"},
                  { "data": "nilai_perolehan" ,class:"text-center"},
                  // { "data": "edit",class:"text-center" },
                  
              ]  
 
          });

          $.fn.dataTableExt.sErrMode = 'throw';
          
        // })
      }
      
    }
  }
}
</script>